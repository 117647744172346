<template>
    <div  class='col-md-10 offset-md-1'>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class='mt-md-10'>
            <v-form ref='form_managment_payment-supplier' v-if="access">
                <v-card tile class='text-center' color='blue-grey lighten-1 white--text py-2 mx-4'>
                    Gestión de pago a proveedor
                </v-card>
                <v-row 
                    class='mt-5' 
                    align="center"
                    justify="center"
                >
                    <v-col cols=12 md=7>
                        <v-row >
                            <v-col cols=12>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-account-star</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>PROVEEDOR </b></v-list-item-title>
                                    <v-list-item-subtitle> {{dataAdvanceSupplier.name}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon >mdi-package-up</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>COTIZACIÓN # </b> </v-list-item-title>
                                    <v-list-item-subtitle> {{dataAdvanceSupplier.quote_id}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>A PAGAR: </b> </v-list-item-title>
                                    <v-list-item-subtitle> ${{dataAdvanceSupplier.a_pagar}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>     
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>MONTO DEL ANTICIPO: </b></v-list-item-title>
                                    <v-list-item-subtitle>${{dataAdvanceSupplier.monto}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>    
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-100</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>FORMA DE PAGO </b></v-list-item-title>
                                    <v-list-item-subtitle> {{dataAdvanceSupplier.payment_method}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>    
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-bank</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>CUENTA DE ORIGEN DEL DÉBITO: </b></v-list-item-title>
                                    <v-list-item-subtitle> {{dataAdvanceSupplier.fin_account_name}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>         
                                
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols=12 md=5> 
                        <v-col class='text-center' v-if="dataAdvanceSupplier.pg_status_id =='PG_APROBADA'" >
                            <v-card elevation="24" class='rounded px-3 py-3'>
                                <v-btn 
                                    block 
                                    color='info' 
                                    @click="downloadTxtCashManagment"
                                >
                                    <v-icon>mdi-download</v-icon> Cash Managment
                                </v-btn>
                                <v-form ref="form_advance_payment">
                                    <v-text-field
                                        v-model='paymentReference'
                                        dense
                                        class='pt-3'
                                        label="Referencia"
                                        outlined
                                        :rules=requiredRule
                                    ></v-text-field>
                                    <!-- <v-file-input
                                        label="Archivo de respuesta"
                                        append-icon='mdi-upload'
                                        class='rounded pt-3'
                                        prepend-icon=''
                                        outlined
                                        dense
                                    ></v-file-input> -->
                                    <v-btn 
                                        class='mx-1' 
                                        small 
                                        color='primary'
                                        @click="manageAdvanceSupplierPayment"
                                    >
                                        Ingresar referencia
                                    </v-btn>
                                    <v-btn 
                                        class='mx-1 white--text' 
                                        small 
                                        color='red' 
                                        @click=rejectedTask
                                    >
                                        Cancelar tarea
                                    </v-btn>
                                </v-form>
                            </v-card>
                        </v-col>
                        <v-col v-else>
                            <v-col v-if="dataAdvanceSupplier.pg_status_id == 'PG_CANCELADA'">
                                <v-alert
                                    type="error"
                                    dense
                                    class='text-center'
                                > 
                                    PAGO CANCELADO
                                </v-alert>                           
                            </v-col>
                            <v-col v-if="dataAdvanceSupplier.pg_status_id == 'PG_COMPLETADA'">
                                <v-alert
                                    type="success"
                                    dense
                                    class='text-center'
                                > 
                                    PAGO REALIZADO
                                </v-alert>
                            </v-col>
                        </v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-list-item dense class='ml-3'>
                        <v-list-item-icon class='mr-2'>
                            <v-icon>mdi-comment-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title><b>DESCRIPCIÓN: </b></v-list-item-title>
                        <v-list-item-subtitle> {{dataAdvanceSupplier.description}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>   
                </v-row>
            </v-form>
            <v-col v-else class='deep-orange darken-1 white--text'>
                <v-row align="center" >
                    <v-col>
                        <v-icon class='white--text'>mdi-sync-alert</v-icon> Usted no es el propietario de la tarea, por lo que o puede completarla
                    </v-col>
                    <v-col class="shrink">
                    <v-btn to='/' color='primary'>Volver a tareas</v-btn>
                    </v-col>
                </v-row>
            </v-col >
        </v-card>
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue'

export default {
    name : 'ManageSupplierPaymentComponent',
    data: ()=> ({
        overlay:true,
        access:false,
        paymentReference:null,
        dataAdvanceSupplier:{},
        requiredRule:[
            v => !!v || 'El campo es requerido'
        ],
    }),
    computed:{
        
        ...mapState('master',['paramAlertQuestion','user'])

    },
    methods:{

         ...mapMutations('master',['setUrl']),

        ...mapActions('master',['requestApi','alertNotification']),
       
        verifyWorkEffort(){

            this.setUrl('lista-cotizacion-proveedor')

            const  {
                workEffortId,
                paymentGroupId
            } = this.$route.params

            this.requestApi({
                method: 'PATCH',
                data: { 
                    workEffortId,
                    paymentGroupId,
                    typeData:'dataAdvanceSupplier'
                }
            }).then(res =>{

                this.access = res.data.detail[0].exists
                this.dataAdvanceSupplier = res.data.detail[1]
                
            }).catch(()=>{
                
            }).then(()=>{
                this.overlay=false  
            })

        },

        downloadTxtCashManagment(){
            
            this.overlay=true
            this.setUrl('lista-cotizacion-proveedor')
            this.requestApi({
                method: 'POST',
                data: { 
                    workEffortId: this.$route.params.workEffortId,
                    paymentGroupId: this.$route.params.paymentGroupId,
                    reference: 'ANTICIPO DE PAGO '+ this.dataAdvanceSupplier.quote_id,
                    typeStore:'fileCashManagment'
                }
            }).then(res =>{
                
                let a = document.createElement("a"); 
                a.href = "data:text/plain;base64," + res.data.res; 
                a.download = 'cash_managment_anticipo_proveedor.txt';
                a.click();

            }).catch(()=>{
                
            }).then(()=>{
                this.overlay=false  
            })

        },

        rejectedTask(){

            Vue.swal({
                html: "Está seguro de rechazar la gestión de anticipo de este proveedor?",
                icon: "warning",
                input: 'textarea',
                inputPlaceholder: 'Escriba un comentario',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion,
                inputValidator: (value) => {
                    if (!value) 
                        return 'Debe escribir un comentario'
                }
            }).then( result => {
                if (result.isConfirmed) {
                    
                    this.overlay=true
                    this.setUrl('lista-cotizacion-proveedor')

                    const  {
                        workEffortId,
                        paymentGroupId
                    } = this.$route.params


                    this.requestApi({
                        method: 'POST',
                        data :{
                            workEffortId,
                            paymentGroupId,
                            quoteId: this.dataAdvanceSupplier.quote_id,
                            description: result.value,
                            typeStore: 'rejectAdvanceSupplier'                            
                        }
                    }).then(res =>{

                        this.alertNotification({param:{html: res.data.res.msg}})
                        location.reload()

			        })

                }
            })

        },

        manageAdvanceSupplierPayment(){

            if(!this.$refs.form_advance_payment.validate())
                return

            this.overlay=true
            this.setUrl('lista-cotizacion-proveedor')

            const  {
                workEffortId,
                paymentGroupId
            } = this.$route.params

            this.requestApi({
                method: 'POST',
                data: { 
                    workEffortId,
                    paymentGroupId,
                    quoteId: this.dataAdvanceSupplier.quote_id,
                    paymentReference: this.paymentReference,
                    typeStore:'manageAdvanceSupplierPayment'
                }
            }).then(res =>{
                
                this.alertNotification({param:{html: res.data.res.msg}})
                location.reload()

            }).catch(()=>{
                
            }).then(()=>{
                this.overlay=false  
            })

        }

    },
    mounted(){
        this.verifyWorkEffort()
    }
}
</script>
